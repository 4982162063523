/* Rudimentry fixes to get IE9 functional, adapted from https://github.com/coliff/bootstrap-ie8 */
/* line 6, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.card-group .card, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
.breadcrumb .breadcrumb-item, .pagination page-item {
  float: left; }

/* line 15, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.row,
.container-fluid,
.container,
section,
footer,
main,
aside {
  clear: both;
  display: block;
  width: 100%;
  height: 100%; }
  /* line 2, src/LondonPandI.Web/Content/bootstrap/mixins/_clearfix.scss */
  .row::after,
  .container-fluid::after,
  .container::after,
  section::after,
  footer::after,
  main::after,
  aside::after {
    display: block;
    clear: both;
    content: ""; }

/* line 30, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.navbar-nav,
.navbar-collapse,
.breadcrumb,
.pagination,
.form-inline {
  display: inline-block; }

@media (min-width: 1200px) {
  /* line 39, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
  .navbar-expand-xl .navbar-nav {
    float: left;
    width: auto; }
    /* line 43, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
    .navbar-expand-xl .navbar-nav .nav-item {
      float: left; } }

/* line 49, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.modal.fade .modal-dialog {
  -ms-transform: translate(0, -25%); }

/* line 53, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.modal.show .modal-dialog {
  -ms-transform: translate(0, 0); }

/* line 57, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.custom-select {
  background-image: none;
  padding-right: .75rem; }

/* line 62, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.input-group {
  overflow: auto; }

/* line 66, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.input-group > :not(:last-child).form-control,
.input-group > :not(:last-child).custom-select {
  float: left;
  width: 80%; }

/* line 72, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.input-group-append {
  float: left; }

/* line 76, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.table-responsive {
  min-height: 0%; }

/* see https://github.com/twbs/bootstrap/issues/14837 */
/* line 81, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.progress {
  display: block; }

/* line 85, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.progress-bar {
  float: left;
  font-size: 12px;
  line-height: 20px;
  width: 0;
  text-align: center;
  height: 100%; }

/* line 94, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.collapse {
  display: inline-block; }

/* line 98, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.d-flex {
  display: block; }

/* line 102, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.d-inline-flex {
  display: inline-block; }

/* line 106, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.flex-row .div {
  display: inline-block; }

/* line 110, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.flex-row-reverse {
  text-align: right; }

/* line 114, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.flex-row-reverse .div {
  display: inline-block;
  float: right; }

/* line 119, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-start div {
  display: inline-block; }

/* line 123, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-end {
  text-align: right; }

/* line 127, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-end div {
  display: inline-block;
  margin-right: 0;
  float: right; }

/* line 133, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-center {
  text-align: center; }

/* line 137, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-center div {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  text-align: center; }

/* line 144, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-between {
  text-justify: distribute-all-lines; }

/* line 148, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-between div {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  text-align: justify; }

/* line 155, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-around {
  text-align: justify;
  text-justify: distribute-all-lines; }

/* see https://stackoverflow.com/questions/6865194/fluid-width-with-equally-spaced-divs */
/* line 160, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.justify-content-around div {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  text-align: justify; }

/* line 167, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
[class^="justify-content-"] div {
  display: inline-block; }

/** Carousel - Hide indicators and controls as the carousel doesn't work **/
/* line 172, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.carousel-indicators,
.carousel-control-prev,
.carousel-control-next {
  display: none; }

/* line 178, src/LondonPandI.Web/Content/sass/components/_bootstrap-ie9.scss */
.text-hide {
  background: url(blank.gif); }

/* line 3, src/LondonPandI.Web/Content/sass/site-ie9.scss */
.navbar-brand {
  margin-right: 28rem; }

/* line 7, src/LondonPandI.Web/Content/sass/site-ie9.scss */
.quicklinks__item {
  display: block; }
